<template>
  <v-app>
    <v-card
      class="ma-0 pa-2"
      color="white"
      light
    >
      <v-row
        justify="center"
      >
        <img
          src="https://static.wixstatic.com/media/f16133_18200aed7349481d94b08966473ed426~mv2.jpg"
          contain
          height="40px"
        >
      </v-row>
    </v-card>
    <v-container
      fluid
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          md="9"
        >
          <monitor />
          <manual />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    components: {
      monitor: () => import('./Monitor'),
      manual: () => import('./Manual'),
    },
  }
</script>
